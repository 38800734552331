import { Button, Flex, Grid } from '@happyfoxinc/web-components'
import cx from 'classnames'
import { useEffect, useState } from 'react'

import styles from './Dashboard.module.css'

import { useGetAccountQuery, useGetProfileQuery } from 'Src/servicesV3/authApi'
import { useGetReportMetricsQuery } from 'Src/servicesV3/reportsApi'
import dayjs from 'Utils/dayjs-helper'

const Dashboard = () => {
  const { data: account = {} } = useGetAccountQuery()
  const { data: user } = useGetProfileQuery()

  const [toDate, setToDate] = useState()

  const fromDate = dayjs(account.allowed_reporting_start_date).format()

  const { data: reports } = useGetReportMetricsQuery(
    { from: fromDate, to: toDate },
    { skip: !account.allowed_reporting_start_date || !toDate }
  )

  useEffect(() => {
    if (account.allowed_reporting_start_date) {
      setToDate(dayjs().format())
    }
  }, [account.allowed_reporting_start_date])

  const coverageQuestions = reports?.coverage_opportunities ?? []

  return (
    <Flex direction='column' align='center' className={styles.container} width='705px'>
      <h2 className={styles.greeting}>Good Morning {user?.name}!</h2>

      <div className={cx(styles.card, styles.topCard)}>
        <div className={styles.newLabel}>New</div>
        <h2 className={styles.updateTitle}>Check Out What's New in Assist AI! 🚀 - Jan 17, 2025</h2>
        <div>
          <p className={styles.updateText}>
            We are excited to announce the following updates available in the latest production release:
          </p>
          <p className={styles.updateText}>
            New Features: Assist AI V3 is now live! 🎉 This release introduces brand-new modules tailored to streamline
            your workflows, along with a sleek UI overhaul for a more engaging user experience. Simplify workflows,
            enhance collaboration, and seamlessly connect with your favorite integrations.
          </p>
        </div>
        <Button variant='soft' className={styles.readMore}>
          Read More
        </Button>
      </div>

      <Grid width='100%' columns='300px 1fr' gap='16px'>
        <div className={styles.card}>
          <div className={styles.textContainer}>
            <h2 className={styles.cardTitle}>Overall Auto Resolution Rate</h2>
            <p className={styles.resolutionSubtitle}>Automated Resolution Rate</p>
          </div>

          <div className={styles.circleContainer}>
            <div className={styles.circleOuter}>
              <div className={styles.circleInner}>
                <span className={styles.percentage}>
                  {(reports?.overall_auto_resolution_rate ?? 0).toFixed(1)}
                  <span className={styles.symbol}>%</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={cx(styles.card, styles.questionCard)}>
          <h3 className={styles.cardTitle}>Coverage Opportunities</h3>
          <Flex direction='column' p='10px 0'>
            {coverageQuestions.length > 0 ? (
              coverageQuestions.map((question, index) => (
                <div key={index} className={styles.questionItem}>
                  {question}
                </div>
              ))
            ) : (
              <div className={styles.questionItem}>No coverage opportunities found</div>
            )}
          </Flex>
        </div>

        <div className={cx(styles.card, styles.statsCard)}>
          <h3 className={styles.statsCardTitle}>Total Active Users</h3>
          <span className={styles.statsNumber}>{reports?.active_users_count ?? '-'}</span>
        </div>

        <div className={cx(styles.card, styles.statsCard)}>
          <h3 className={styles.statsCardTitle}>Total Requests</h3>
          <span className={styles.statsNumber}>{reports?.requests_count ?? '-'}</span>
        </div>
      </Grid>
    </Flex>
  )
}

export default Dashboard
