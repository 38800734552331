import { createApi } from '@reduxjs/toolkit/query/react'

import { customBaseQuery } from 'Src/customBaseQuery'

export const TAGS = {
  ACCOUNT: 'Account',
  PROFILE: 'Profile',
  USERS: 'Users',
  USER_GROUPS: 'UserGroups',
  WORKSPACES: 'Workspaces',
  COMPANY_POLICIES: 'CompanyPolicies',
  COMPANY_POLICY_FILES: 'CompanyPolicyFiles',
  SOFTWARE_ACCESS_MODULE: {
    APP_PROVIDER: 'AppProvider',
    SOFTWARES: 'Softwares',
    PROVISION_STEPS: 'ProvisionSteps',
    STEP_DETAILS: 'StepDetails',
    ACCESS_CONTROL: 'AccessControl'
  },
  SURVEYS: {
    LIST: 'SurveysList',
    DETAILS: 'SurveyDetails',
    QUESTIONS: 'SurveyQuestions',
    REPORTS: 'SurveyReports'
  },
  CAMPAIGNS: {
    LIST: 'CampaignsList',
    DETAILS: 'CampaignDetails'
  },
  ANSWERS: 'Answers',
  SLACK_CHANNELS: 'SlackChannels',
  ADMINS: 'Admins',
  HELPDESK_APP: 'HelpdeskApp',
  HELPDESK_APP_EMPLOYEE_NOTIFICATIONS: 'HelpdeskAppEmployeeNotifications',
  SERVICDESK_APP: 'ServicedeskApp',
  REPORTS: 'ReportMetrics'
}

const flattenTags = (obj) => {
  return Object.values(obj).reduce((acc, val) => {
    if (typeof val === 'string') {
      return [...acc, val]
    }
    if (typeof val === 'object' && val !== null) {
      return [...acc, ...flattenTags(val)]
    }
    return acc
  }, [])
}

export const baseApi = createApi({
  reducerPath: 'apiV3',
  baseQuery: customBaseQuery,
  tagTypes: flattenTags(TAGS),
  endpoints: () => ({})
})

export default baseApi
